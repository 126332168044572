import React, { useState, useEffect, useCallback } from "react";
import ImageSideNavPanel from "./components/image_side_nav_panel";
import { PANELS } from "./components/panelConfig";
import { GenerateMediaResult, Panel, GenerativeModel, VersionHistory, GenerativeModelWeight, UpscaleRequest, UserFeedback, BoardDTO } from "./models/image_generator";
import { useParams } from "react-router-dom";
import { useGenerativeAPI } from "../../api/generative_ai_api";
import DescriptionPanel from "./components/panels/description_panel";
import VersionHistoryPanel from "./components/panels/version_history_panel";
import AspectRatioPanel from "./components/panels/aspect_ratio_panel";
import VersionHistoryListPanel from "./components/panels/version_history_list_panel";
import EditDirectlyPanel from "./components/panels/edit_directly_panel";
import FeedbackPanel from "./components/panels/feedback_panel";
import ZoomPanel from "./components/panels/zoom_panel";
import { useImageZoom } from "../../hooks/image_zoom";
import ImageEditCanvas from "./components/modal-components/image_edit_canvas";
import { DialogModal } from "../../common/components/ui/dialog_modal";
import SaveImageContent from "./components/modal-components/save_image";
import { useImageBoardAPI } from "../../api/image_board_api";

const ImageEditor = () => {
    const { taskQueueId: initialTaskQueueId } = useParams<{ taskQueueId: string }>();
    const [taskQueueId, setTaskQueueId] = useState<string | null>(initialTaskQueueId || null);
    const [generativeData, setGenerativeData] = useState<GenerateMediaResult | null>(null);
    const [modelList, setModelList] = useState<GenerativeModel[]>([]);
    const [versionHistory, setVersionHistory] = useState<VersionHistory[]>([]);
    const [isDragActive, setIsDragActive] = useState(false);
    const [isZoomActive, setIsZoomActive] = useState(false);
    const [drawnPortions, setDrawnPortions] = useState<{ x: number; y: number; radius: number }[][]>([]);
    const [isInpaint, setIsInpaint] = useState(false);
    const [cursorSize, setCursorSize] = useState(32);
    const [mask, setMask] = useState<string | null>(null);
    const [selectedVersionHistory, setSelectedVersionHistory] = useState<VersionHistory | null>(null);
    const [selectedFeedback, setSelectedFeedback] = useState<"up" | "down" | null>();
    const [showLoadingImage, setShowLoadingImage] = useState(false);
    const [headerText, setHeaderText] = useState<string>("");
    const [emptyPanelState, setEmptyPanelState] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [openSaveModal, setOpenSaveModal] = useState(false);
    const [boards, setBoards] = useState<BoardDTO[]>([]);
    const [panels, setPanels] = useState<Panel[]>(() =>
        PANELS.map(panel => ({
            ...panel,
            active: false,
            isExpanded: true,
        }))
    );

    const [actionToast, setActionToast] = useState<
        {
            showToast: boolean,
            toastMessage: string,
        }>({
            showToast: false,
            toastMessage: "",
        });

    let intervalId: NodeJS.Timeout;
    const headerTexts = [
        "Ta-da! Your image is ready.",
        "Done and done! Your image is ready.",
        "Boom! Your image is here."
    ]

    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * headerTexts.length);
        setHeaderText(headerTexts[randomIndex]);
    }, []);

    const {
        getGenerativeOutput,
        generateImageMedia,
        checkTaskQueueStatus,
        getVersionHistory,
        getModels,
        upscaleImage,
        saveFeedback
    } = useGenerativeAPI();

    const { getImageBoards, addOrRemoveFavorite } = useImageBoardAPI();

    const {
        scale,
        isDragging,
        imagePosition,
        imageRef,
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
        handleMouseLeave,
        handleReset,
        handleZoomUndo,
        handleZoomIn,
        handleZoomOut,
    } = useImageZoom();

    const dragHandlers = isDragActive ? {
        onMouseMove: handleMouseMove,
        onMouseUp: handleMouseUp,
        onMouseLeave: handleMouseLeave
    } : {};

    useEffect(() => {
        const fetchGenerativeData = async () => {
            if (!taskQueueId) return;

            try {
                const [generativeData, childVersionHistory] = await Promise.all([
                    getGenerativeOutput(taskQueueId),
                    getVersionHistory(taskQueueId)
                ]);

                const parentVersionHistory: VersionHistory[] = generativeData.outputs.map(output => ({
                    generative_output_id: output.generative_output_id,
                    file_id: output.file_id,
                    permalink: output.permalink,
                    asset_type: output.asset_type,
                    created_at: output.created_at,
                    action: generativeData.action,
                    parentTaskId: generativeData.parentTaskId,
                    seed: generativeData.seed,
                    task_queue_id: taskQueueId,
                    aspect_ratio: generativeData.aspect_ratio,
                    height: generativeData.height,
                    width: generativeData.width,
                    prompt: generativeData.prompt,
                    inpaint_prompt: generativeData.inpaint_prompt,
                }));

                const combinedVersionHistory = [...parentVersionHistory, ...childVersionHistory];
                combinedVersionHistory.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

                setVersionHistory(combinedVersionHistory);

                setPanels(currentPanels =>
                    currentPanels.map(panel => ({
                        ...panel,
                        active: panel.id === "Description" || panel.id === "Versions" ? true : panel.active
                    }))
                );
                setSelectedVersionHistory(parentVersionHistory[0]);
                setGenerativeData(generativeData);

            } catch (error) {
                console.error("Error fetching task queue data", error);
            }
        };

        fetchGenerativeData();
    }, [taskQueueId]);

    useEffect(() => {
        const fetchModelList = async () => {
            try {
                const modelTypes = ["USER_PRODUCT", "BRAND", "LICENSABLE_PROPERTY"] as const;
                const responses = await Promise.all(
                    modelTypes.map(type => getModels(type))
                );

                const combinedModels = responses.flatMap((response, index) =>
                    response.map(model => ({
                        ...model,
                        model_type: modelTypes[index].toLowerCase()
                    }))
                );

                setModelList(combinedModels);
            } catch (error) {
                console.error("Error fetching models", error);
            }
        };

        fetchModelList();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const myBoards = await getImageBoards();
                setBoards(myBoards);
            } catch (error) {
                console.error("Error fetching generative output", error);
            }
        };
        fetchData();
    }, []);

    // const handleSavePrompt = useCallback((newPrompt: string) => {
    //     setGenerativeData((prevData) => {
    //         if (!prevData) return null;
    //         return { ...prevData, prompt: newPrompt }
    //     });

    //     regenerateImage(newPrompt, generativeData?.aspect_ratio, generativeData?.model_weights, undefined, generativeData?.task_queue_id, "generate_media");
    // }, [generativeData]);

    const generateVariations = async () => {
        const prompt_text = generativeData?.prompt || "Generate variations";
        // if (!prompt_text) {
        //     // TODO: Get a real dialog box here
        //     prompt_text = prompt("Please describe what you would like to see in the variation")
        // }
        // if (!prompt_text) {
        //     prompt_text = "Generate variations"
        // }
        return regenerateImage(
            prompt_text,
            generativeData?.aspect_ratio,
            generativeData?.model_weights,
            selectedVersionHistory?.file_id,
            generativeData?.task_queue_id,
            "generate_media")
    }

    useEffect(() => {
        const dragPanel = panels.find(p => p.id === "Drag");
        const zoomPanel = panels.find(p => p.id === "Zoom");
        const isEditDirectlyActive = panels.find(p => p.id === "Edit directly");
        setIsDragActive(dragPanel?.active || false);
        setIsZoomActive(zoomPanel?.active || false);
        setIsInpaint(isEditDirectlyActive?.active || false);
    }, [panels]);

    useEffect(() => {
        const activePanels = panels.some(
            panel => !panel.isShort && panel.active
        );
        setEmptyPanelState(!activePanels);
    }, [panels]);


    const handlePanelClick = (clickedPanelId: string, keepOthersExpanded = false) => {
        switch (clickedPanelId) {
            case "Upscale":
                fetchUpscaleImage();
                break;
            case "Save image":
                setOpenSaveModal(true);
                break;
            case "Favorite image":
                handleFavoriteImage();
                break;
            case "Download image":
                downloadImage();
                break;
        }


        setPanels(currentPanels => {
            const clickedPanel = currentPanels.find(p => p.id === clickedPanelId);
            const isActivating = !clickedPanel?.active;
            const isShortPanel = clickedPanel?.isShort;

            return currentPanels.map(panel => ({
                ...panel,
                active: panel.id === clickedPanelId
                    ? !panel.active
                    : (panel.id === "Zoom" && clickedPanelId === "Drag" && isActivating) ||
                        (panel.id === "Drag" && clickedPanelId === "Zoom" && isActivating) ||
                        (panel.id === "Edit directly" && clickedPanelId === "Drag" && isActivating) ||
                        (panel.id === "Drag" && clickedPanelId === "Edit directly" && isActivating)
                        ? false
                        : panel.active,
                isExpanded: isShortPanel
                    ? panel.isExpanded
                    : (isActivating
                        ? (panel.id === clickedPanelId || (keepOthersExpanded && panel.isExpanded))
                        : panel.isExpanded)
            }));
        });
    }

    const handleToggleExpand = (panelId: string) => {
        setPanels(currentPanels =>
            currentPanels.map(panel => ({
                ...panel,
                isExpanded: panel.id === panelId ? !panel.isExpanded : panel.isExpanded
            }))
        );
    };

    const renderPanelContent = (panelId: string) => {
        switch (panelId) {
            case "Description":
                return (
                    <DescriptionPanel
                        generativeData={generativeData}
                        modelList={modelList}
                        handleToggleExpand={() => handleToggleExpand("Description")}
                        expanded={panels.find(panel => panel.id === "Description")?.isExpanded}
                        updatePrompt={generateNewImage}
                        closePanel={() => handlePanelClick("Description")}
                        helperText={panels.find(panel => panel.id === "Description")?.details}
                    />
                );
            case "Versions":
                return (
                    <VersionHistoryPanel
                        versionHistory={versionHistory}
                        selectedVariant={selectedVersionHistory}
                        handleToggleExpand={() => handleToggleExpand("Versions")}
                        expanded={panels.find(panel => panel.id === "Versions")?.isExpanded}
                        setSelectedVariant={setSelectedVersionHistory}
                        showLoadingImage={showLoadingImage}
                        generateVariation={generateVariations}
                        closePanel={() => handlePanelClick("Versions")}
                        helperText={panels.find(panel => panel.id === "Versions")?.details}
                    />
                );
            case "Aspect ratio":
                return (
                    <AspectRatioPanel
                        generativeData={generativeData}
                        handleAspectRatioChange={editAspectRatio}
                        handleToggleExpand={() => handleToggleExpand("Aspect ratio")}
                        expanded={panels.find(panel => panel.id === "Aspect ratio")?.isExpanded}
                        closePanel={() => handlePanelClick("Aspect ratio")}
                        helperText={panels.find(panel => panel.id === "Aspect ratio")?.details}
                    />
                )

            case "History":
                return (
                    <VersionHistoryListPanel
                        versionHistory={versionHistory}
                        handleToggleExpand={() => handleToggleExpand("History")}
                        expanded={panels.find(panel => panel.id === "History")?.isExpanded}
                        closePanel={() => handlePanelClick("History")}
                        helperText={panels.find(panel => panel.id === "History")?.details}
                    />
                )
            case "Edit directly":
                return (
                    <EditDirectlyPanel
                        currentVersionHistory={selectedVersionHistory}
                        showInpaint={generateMask}
                        modelList={modelList}
                        cursorSize={cursorSize}
                        mask={mask}
                        setCursorSize={setCursorSize}
                        resetDraw={resetCanvas}
                        undoLastDraw={undoLastDraw}
                        generateInpaint={generateInpaint}
                        handleToggleExpand={() => handleToggleExpand("Edit directly")}
                        expanded={panels.find(panel => panel.id === "Edit directly")?.isExpanded}
                        closePanel={() => handlePanelClick("Edit directly")}
                        helperText={panels.find(panel => panel.id === "Edit directly")?.details}
                    />
                )
            case "Upscale":
                return null;
            case "Feedback":
                return (
                    <FeedbackPanel selectedFeedback={selectedFeedback} handleSelect={(feedback: any) => handleFeedback(feedback)} />
                )
            case "Zoom":
                return (
                    <ZoomPanel scale={scale} zoomIn={handleZoomIn} zoomOut={handleZoomOut} />
                )
            case "Drag":
                return (
                    <div
                        className="absolute w-[183px] left-full select-none top-0 bg-white text-blackish rounded-lg shadow-lg p-4 z-50 hover:cursor-pointer "
                        style={{ marginLeft: "13.5px" }}
                    >
                        <div className="flex flex-col gap-3">
                            <p className="font-sans">Move and pan</p>
                            <p className="font-[200]">Drag image to see more</p>
                            <div className="flex gap-2">
                                <div onClick={handleReset} className="border-2 px-2 py-1 border-gray-300 rounded-lg flex items-center justify-center">Reset</div>
                                <div onClick={handleZoomUndo} className="border-2 py-1 px-2 border-gray-300 rounded-lg flex items-center justify-center">Undo</div>
                            </div>
                        </div>
                    </div>
                )
            case "Save image":
                return null;
            case "Favorite image":
                return null;
            case "Download image":
                return null;
            default:
                return null;
        }
    };

    const generateInpaint = async (inpaintPrompt: string, modelWeights: { id: string; weight: number; }[], mask: string | null) => {
        if (!mask) {
            showToast("Please select the area to inpaint");
            return;
        }
        setShowLoadingImage(true);
        setPanels(currentPanels =>
            currentPanels.map(panel => ({
                ...panel,
                active: panel.id === "Versions" ? true : panel.active,
                isExpanded: panel.id === "Versions" ? true : panel.isExpanded
            }))
        );
        resetCanvas();
        try {
            const response = await generateImageMedia({
                prompt: selectedVersionHistory?.prompt ? selectedVersionHistory.prompt : generativeData ? generativeData.prompt : "",
                aspect_ratio: generativeData?.aspect_ratio || "SQUARE",
                image_file_id: selectedVersionHistory?.file_id || generativeData?.outputs[0].file_id || "",
                model_weights: modelWeights || [],
                seed: generativeData?.seed,
                num_images_per_prompt: 1,
                mask: mask,
                parent_task_id: generativeData?.task_queue_id || undefined,
                inpaint_prompt: inpaintPrompt,
                inpaint_image_file_id: null,
            });

            intervalId = setInterval(() => checkStatusMethod(response.id, intervalId), 1000);
            setTimeout(() => handleTimeout(), 2 * 60 * 1000);
        } catch (error) {
            setShowLoadingImage(false);
            showToast("Error generating image");
            console.error("Error generating image", error);
        }
    }

    const editAspectRatio = useCallback((newAspectRatio: string) => {
        setGenerativeData((prevData) => {
            if (!prevData) return null;
            return { ...prevData, aspect_ratio: newAspectRatio }
        });
        regenerateImage("", newAspectRatio, generativeData?.model_weights, selectedVersionHistory?.file_id, generativeData?.task_queue_id, "outpainting");
    }, [generativeData, selectedVersionHistory]);


    const handleFavoriteImage = async () => {
        if (!selectedVersionHistory) {
            showToast("Error saving image");
            return;
        }

        try {
            await addOrRemoveFavorite(selectedVersionHistory.generative_output_id);
            showToast("Image saved to Favorites");
            setTimeout(() => {
                setPanels(currentPanels =>
                    currentPanels.map(panel => ({
                        ...panel,
                        active: panel.id === "Favorite image" ? false : panel.active
                    }))
                );
            }, 1000);

        } catch (error) {
            showToast("Error saving favorite image");
            console.error("Error saving image", error);
        }
    }


    const closeSavePanel = (boardName?: string) => {
        setOpenSaveModal(false);
        setPanels(currentPanels =>
            currentPanels.map(panel =>
                panel.id === "Save image" ? { ...panel, active: false } : panel
            )
        );

        if (boardName) {
            showToast(`Image saved to ${boardName}`);
        }


    }


    const downloadImage = () => {
        if (!generativeData) return;
        const link = document.createElement("a");
        link.href = selectedVersionHistory ? selectedVersionHistory.permalink : generativeData?.outputs[0].permalink;
        link.download = "image.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showToast("Image downloaded");
        setTimeout(() => {
            setPanels(currentPanels =>
                currentPanels.map(panel => ({
                    ...panel,
                    active: panel.id === "Download image" ? false : panel.active
                }))
            );
        }, 1000);
    }

    const handleFeedback = async (feedback: any) => {
        setSelectedFeedback(selectedFeedback === feedback ? null : feedback);
        const payload: UserFeedback = {
            rating: feedback === "up" ? 5 : 1,
            feedback: feedback === "up" ? "Thumbs up from image gen" : "Thumbs down from image gen",
            is_public: true,
            entity_type: "GENERATIVE_OUTPUT",
            entity_id: selectedVersionHistory?.generative_output_id,
            created_at: new Date().toISOString()
        }
        try {
            await saveFeedback(payload);
            showToast("Feedback submitted!");
        } catch (error) {
            showToast("Error submitting feedback");
            console.error("Error submitting feedback", error);
        }

        setTimeout(() => {
            setPanels(currentPanels =>
                currentPanels.map(panel => ({
                    ...panel,
                    active: panel.id === "Feedback" ? false : panel.active
                }))
            );
        }, 1000);

    }

    const showToast = (message: string) => {
        setActionToast({ showToast: true, toastMessage: message });
        setTimeout(() => {
            setActionToast(prevState => ({ ...prevState, showToast: false, toastMessage: "" }));
        }, 3000);
    }


    const generateMask = (base64Mask: any) => {
        setMask(base64Mask);
    }

    const undoLastDraw = () => {
        setDrawnPortions((prev) => prev.slice(0, -1));
    };

    const resetCanvas = () => {
        setDrawnPortions([]);
    };

    const fetchUpscaleImage = async () => {
        if (!selectedVersionHistory || !taskQueueId) {
            showToast("Error upscaling image");
            return;
        }

        const payload: UpscaleRequest = {
            generative_output_id: selectedVersionHistory.generative_output_id,
            file_id: selectedVersionHistory.file_id,
            seed: generativeData?.seed,
            parent_task_id: generativeData?.task_queue_id
        }

        try {
            setShowLoadingImage(true);
            setPanels(currentPanels =>
                currentPanels.map(panel => ({
                    ...panel,
                    active: panel.id === "Versions" ? true : panel.active,
                    isExpanded: panel.id === "Versions" ? true : panel.isExpanded
                }))
            );
            const response = await upscaleImage(payload);
            setPanels(currentPanels =>
                currentPanels.map(panel => ({
                    ...panel,
                    active: panel.id === "Upscale" ? false : panel.active
                }))
            );
            intervalId = setInterval(() => checkStatusMethod(response.id, intervalId), 1000);
            setTimeout(() => handleTimeout(), 2 * 60 * 1000);
        } catch (error) {
            console.error();
            showToast("Error upscaling image");
        }
    }

    const regenerateImage = async (prompt?: string, aspect_ratio?: string, model_weights?: GenerativeModelWeight[], image_file_id?: string, parent_task_id?: string, action?: string) => {
        setShowLoadingImage(true);
        setPanels(currentPanels =>
            currentPanels.map(panel => ({
                ...panel,
                active: panel.id === "Versions" ? true : panel.active,
                isExpanded: panel.id === "Versions" ? true : panel.isExpanded
            }))
        );
        try {
            const response = await generateImageMedia({
                prompt: prompt || generativeData?.prompt || "",
                aspect_ratio: aspect_ratio || generativeData?.aspect_ratio || "SQUARE",
                num_images_per_prompt: 1,
                image_file_id: image_file_id || selectedVersionHistory?.file_id || generativeData?.outputs[0].file_id || "",
                model_weights: model_weights || generativeData?.model_weights || [],
                seed: generativeData?.seed,
                parent_task_id: parent_task_id || undefined,
                action: action || "generate_media"
            });

            intervalId = setInterval(() => checkStatusMethod(response.id, intervalId), 1000);
            setTimeout(() => handleTimeout(), 2 * 60 * 1000);
        } catch (error) {
            setShowLoadingImage(false);
            showToast("Error generating image");
            console.error("Error generating image", error);
        }
    }

    const generateNewImage = async (prompt: string, model_weights: GenerativeModelWeight[]) => {
        setShowLoadingImage(true);
        setPanels(currentPanels =>
            currentPanels.map(panel => ({
                ...panel,
                active: panel.id === "Versions" ? true : panel.active,
                isExpanded: panel.id === "Versions" ? true : panel.isExpanded
            }))
        );

        try {
            const response = await generateImageMedia({
                prompt: prompt,
                parent_task_id: selectedVersionHistory?.task_queue_id,
                aspect_ratio: selectedVersionHistory?.aspect_ratio || generativeData?.aspect_ratio || "SQUARE",
                num_images_per_prompt: 1,
                model_weights: model_weights || []
            });
            intervalId = setInterval(() => checkStatusMethod(response.id, intervalId), 1000);
            setTimeout(() => handleTimeout(), 2 * 60 * 1000);
        } catch (error) {
            setShowLoadingImage(false);
            showToast("Error generating image");
            console.error("Error generating image", error);
        }
    }


    const newFetchGenOutput = async (taskQueueId: string) => {
        // Will we always only have one image returned at a time?
        try {
            const response = await getGenerativeOutput(taskQueueId);
            const latestVariation = response.outputs.map(output => ({
                generative_output_id: output.generative_output_id,
                file_id: output.file_id,
                permalink: output.permalink,
                asset_type: output.asset_type,
                action: response.action,
                parentTaskId: response.parentTaskId,
                seed: response.seed,
                task_queue_id: response.task_queue_id,
                aspect_ratio: response.aspect_ratio,
                height: response.height,
                width: response.width,
                prompt: response.prompt,
                inpaint_prompt: response.inpaint_prompt,
                created_at: output.created_at,
            }))
            versionHistory.push(latestVariation[0]);
            setSelectedVersionHistory(latestVariation[0]);
        } catch (error) {
            console.error("Error fetching generative output", error);
        }
    }

    const checkStatusMethod = async (updatedTaskQueueId: string, intervalId: NodeJS.Timeout) => {
        try {
            const response = await checkTaskQueueStatus(updatedTaskQueueId);
            let failedTask = null;
            let completedTask = null;
            for (let i = response.length - 1; i >= 0; i--) {
                if (response[i].status === "completed") {
                    completedTask = response[i];
                    break;
                }
                if (response[i].status === "failed") {
                    failedTask = response[i];
                    break;
                }
            }

            if (failedTask) {
                setShowLoadingImage(false);
                clearInterval(intervalId);
                showToast("Failed to generate image: " + failedTask.progress_message);
                return;
            }
            if (completedTask && completedTask.progress_status === "failed") {
                setShowLoadingImage(false);
                clearInterval(intervalId);
                showToast("Failed to generate image: " + completedTask.progress_message);
                return;
            }

            if (completedTask && completedTask.progress_status === "completed") {
                clearInterval(intervalId);
                setShowLoadingImage(false);
                showToast(headerText);
                newFetchGenOutput(updatedTaskQueueId);
                return;
            }

        } catch (error) {
            console.error("Error checking status", error);
        }
    }

    const handleTimeout = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setShowLoadingImage(false);
            showToast("Oops! It's taking a little too long to create your image. You can retry in a moment!");
        }
    }


    const maskImageElement = document.getElementById("mask-preview") as HTMLImageElement;
    if (maskImageElement) {
        maskImageElement.src = mask ? mask : "";
    }


    return (
        <div className="px-[60px] pt-[120px] flex flex-row justify-between">
            <DialogModal
                isOpen={openSaveModal}
                onOpenChange={setOpenSaveModal}
                // variant="large"
                onClose={closeSavePanel}
            >
                <SaveImageContent generativeOutputId={selectedVersionHistory && selectedVersionHistory.generative_output_id} taskQueueId={taskQueueId} boards={boards} closeModal={closeSavePanel} />
            </DialogModal>
            <ImageSideNavPanel panels={panels} onPanelClick={handlePanelClick} renderPanelContent={renderPanelContent} />
            <div className={`transition-all duration-300 w-1/3 space-y-6 h-[80vh] overflow-auto
                ${!emptyPanelState ? "opacity-100 max-w-[33%]" : "opacity-0 max-w-0 overflow-hidden"}`}
            >
                {panels.map((panel, index) => (
                    <div className="transition-all duration-300" key={index}>
                        {panel.active && !panel.isShort && renderPanelContent(panel.id)}
                    </div>
                ))}
            </div>

            <div
                {...dragHandlers}
                onMouseDown={(e) => {
                    e.preventDefault();
                    if (isDragActive) {
                        handleMouseDown(e);
                    }
                }}
                className={`relative select-none overflow-hidden bg-gray-900 flex items-center justify-center transition-all duration-300
                    ${isDragActive ? "cursor-grab" : ""}
                    ${emptyPanelState ? "w-full ml-8" : "w-[685px] h-[750px]"}`}
            >
                {isInpaint ? (
                    <ImageEditCanvas
                        cursorSize={cursorSize}
                        drawnPortion={drawnPortions}
                        setDrawnPortion={setDrawnPortions}
                        handleMaskGenerated={generateMask}
                        image={selectedVersionHistory?.permalink ?? ""}
                        style={{
                            transform: `scale(${scale}) translate(${imagePosition.x}px, ${imagePosition.y}px)`,
                            transition: isDragging ? "none" : "transform 0.2s",
                        }}
                    />
                ) : (
                    <img
                        ref={imageRef}
                        className={`transition-all duration-300 h-auto
                        ${!isLoaded ? "invisible" : "visible"}
                         ${emptyPanelState ? "w-[685px]" : "w-full"}`}
                        style={{
                            transform: `scale(${scale}) translate(${imagePosition.x}px, ${imagePosition.y}px)`,
                            transition: isDragging ? "none" : "transform 0.2s",
                        }}
                        onLoad={() => setIsLoaded(true)}
                        src={selectedVersionHistory?.permalink ?? ""}
                        alt="Generated Image"
                    />
                )}
            </div>

            {actionToast.showToast && (
                <div className="absolute select-none rounded-[20px] bottom-12 right-9 bg-white px-6 py-5 text-black w-[453px]">
                    {actionToast.toastMessage}
                </div>
            )}
        </div>
    )
}

export default ImageEditor;
