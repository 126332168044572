import React, { useState, useEffect, useRef } from "react";
import bit_image_2 from "../../../../common/components/ui/bit_image_2.png";
import Heading from "../../../../common/components/heading";
import { Button } from "../../../../common/components/ui/button";
import { useImageBoardAPI } from "../../../../api/image_board_api";
import { BoardDTO } from "../../models/image_generator";
import { SelectedIcon, ErrorIcon } from "../../../../common/icons/icons";
import { DownCarret } from "../../../../common/icons/icons";

const SaveImageContent = ({ generativeOutputId, taskQueueId, boards, closeModal }: { generativeOutputId: string | null, taskQueueId: string | null, boards: BoardDTO[], closeModal: (boardName?: string) => void }) => {
    const [displayDropdown, setDisplayDropdown] = useState(false);
    const [selectedBoard, setSelectedBoard] = useState<BoardDTO | null>();
    const [newBoardContent, setNewBoardContent] = useState(false);
    const [boardName, setBoardName] = useState("");
    const [error, setError] = useState("");

    const { saveNewBoard, saveImageToBoard } = useImageBoardAPI();

    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDisplayDropdown(false);
            }
        };

        if (displayDropdown) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [displayDropdown]);

    const saveToBoard = async () => {
        const boardId = selectedBoard ? selectedBoard.id : boards.find(board => board.is_default)?.id;
        const response = await saveImageToBoard(boardId || "", generativeOutputId || "");
        closeModal(response.name);
    }

    const createImageBoard = async () => {
        if (!boardName.trim()) {
            setError("Board name is required");
            return;
        } else {
            setError("");
        }
        if (!generativeOutputId || !taskQueueId) return;
        const request: BoardDTO = {
            created_at: Date.now().toString(),
            name: boardName,
            generative_outputs: [
                {
                    "generative_output_id": generativeOutputId,
                    "task_queue_id": taskQueueId,
                    asset_type: "",
                    file_id: "",
                    permalink: "",
                    created_at: ""
                }
            ]
        }
        const response = await saveNewBoard(request);
        setBoardName("");
        closeModal(response.name);

    };

    return (
        <div className="flex flex-col gap-4 justify-center">
            <img className="rounded-lg" src={bit_image_2} />
            <Heading as="h3">{newBoardContent ? "Create image board" : "Save image"}</Heading>
            {newBoardContent ?
                <div className="flex flex-col space-y-7">
                    <div className="space-y-2">
                        <div className="flex flex-col">
                            <label className="italic font-extralight">Name of board</label>
                            <input
                                className={`border-0 border-b-2 ${error ? "border-brand-error" : "border-blackish"} pt-2 focus:ring-0 outline-none`}
                                type="text"
                                value={boardName}
                                onChange={(e) => { setBoardName(e.target.value); setError("") }} />
                        </div>
                        {error && <div className="text-brand-error text-sm flex flex-row items-center gap-2"><ErrorIcon />{error}</div>}
                    </div>
                    <Button className="self-start w-auto" variant="primary-negative" onClick={createImageBoard}>Create board</Button>
                </div> :
                <div className="space-y-2"> <p>{`Save image to your ${selectedBoard ? selectedBoard.name : "Saved Images"} folder or save to an existing project for easy organization.`}</p>
                    <div className="relative">
                        <Button variant="outline" onClick={() => setDisplayDropdown(true)} className="text-black self-start gap-2 border-gray-300 hover:border-blackish hover:bg-transparent">
                            {selectedBoard ? selectedBoard.name : <>Select <DownCarret /></>}</Button>
                        {displayDropdown && <div ref={dropdownRef} className="text-white w-full h-[225px] cursor-pointer absolute mt-2 p-7 space-y-3 rounded-2xl z-50 bg-blackish overflow-scroll">
                            {boards.map(board => <div key={board.id} onClick={() => { setSelectedBoard(board); setDisplayDropdown(false) }} className="p-1 font-body flex flex-row justify-between">
                                {board.name}
                                {selectedBoard?.id === board.id && <SelectedIcon />}
                            </div>)}
                            <Button onClick={() => setNewBoardContent(true)} className="hover:bg-brand-yellow">Create new board</Button>
                        </div>}
                    </div>
                    <Button variant="primary-negative" onClick={saveToBoard} className="self-start w-auto">Save</Button>
                </div>

            }
        </div>
    )
}

export default SaveImageContent;